@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 16px;
	line-height: 24px;
}
h1 {
	font-size:48px;
	letter-spacing:0px;
	text-transform:inherit;
	font-family: 'Roboto', sans-serif;
	font-weight:500;
	color:#000;
    line-height: 62px;
}
h2 {
	font-size:28px;
	font-weight:500;
	letter-spacing:0;
	text-transform: none !important;
	line-height:32px;
	font-family: 'Roboto', sans-serif;
}
h3 {
	font-size:22px;
    line-height: 26px;
	font-weight:500;
	line-height:inherit;
	font-family: 'Roboto', sans-serif;
}
h4 {
	font-weight:500;
	font-size:18px;
	font-family: 'Roboto', sans-serif;
}
h6 {
	font-size:18px;
	letter-spacing:0px;
	text-transform:uppercase;
	font-family: 'Roboto', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Roboto', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 14px;
	color: #fff;
	font-family: 'Roboto', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#0063A4;
	box-shadow:none;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #777;
	font-family: 'Roboto', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #777;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #777;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
}
.links > li > a:hover {
	background-color: #0063A4;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
	background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #fff;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #0063A4;
}
#nav .nav-panel--dropdown a {
	color: #0063A4;
	font-size: 18px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 18px;
}
#nav .nav-panel--dropdown a:hover {
	color: #0063A4;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #fff;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #fff;
}
#nav .nav-item.level0.parent>a .caret {
    border-top-color: #fff;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #fff;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #0063A4;
	border-right-color: #0063A4;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #0063A4 !important;
	padding: 0;
	border-radius: 0;
	max-height: 45px;
	font-size: 16px;
	margin-top: 10px;
}
#mini-cart .actions button.button span span:hover {
	background-color: #000 !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 48px;
	color: #000;
	letter-spacing: 0px;
	text-transform: inherit;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #0063A4;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 16px;
}
button.button span span {
	padding: 0 24px;
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #000 !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 14px;
	color: #000;
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color:rgba(0, 99, 164, 0.1);
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #353A41;
}
.footer-container, .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #353A41;
}
.footer-top-container {
	padding-top: 10px;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #353A41;
	color: #fff;
    display: none;
}


/************************** MICS **************************/

/*Header*/
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
.header-top-container .module-header-multi .item-welcome-msg{
     margin-left:0;
}
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     height:32px;
     line-height:32px;
}
.top-header-container .module-header-multi p{
     margin-bottom:0 !important;
     height:32px;
     line-height:32px;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a, .userSwitch a, .welcome-msg {
     font-size:16px;
     color:#fff !important;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .links>li>a {
     padding-left:0;
     text-transform:inherit;
     font-size:14px;
}
.top-header-container .links > li:not(:first-child) > a:after {
     content:"|";
     margin-left:0.5833em;
}
.module-user-links .links .link-account::before {
     content:"My";
}
.module-user-links .links>li>a:before{
     font-family:"Roboto";
     margin-right:3px;
}
.top-header-container .links {
     display:flex;
}
.top-header-container .links > li:nth-child(1) {
     order:3;
}
.top-header-container .links > li:nth-child(2) {
     order:2;
}
.top-header-container .links > li:nth-child(3) {
     order:1;
}
.userSwitch a:hover {
     color:#fff;
}
.userSwitch a {
     text-indent:-9999px;
     position:absolute;
}
@media only screen and (max-width:1199px) {
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
@media only screen and (min-width:1200px) {
     .userSwitch a::after {
         content:"(Switch customers)";
    }
     .userSwitch a::after {
         text-indent:0;
         float:left;
    }
}
.desktop-top-header .header-top-container, .mobile-header .header-top-container {
     background-color:#fff;
}
.std i {
     font-style:normal;
}
.desktop-top-header {
     padding:10px 0;
}
.desktop-top-header .grid-container {
     display:flex;
}
.desktop-top-header .logo-column, .desktop-top-header .cart-column {
     margin:auto 0;
}
.desktop-top-header .logo-column .logo img {
     max-width:300px;
}
.desktop-top-header .search-column {
     margin:auto;
     margin-right:0;
}
.desktop-top-header .form-search {
     width:90%;
     margin:0 auto;
}
.desktop-top-header .module-search .form-search input::placeholder {
     color:#333 !important;
     font-weight:400;
     text-align:center;
}
.module-search .form-search .button-search .fa {
     font-weight:600;
}
.desktop-top-header .module-search .form-search .input-text {
     border-radius:5px;
     background-color:#EFEAEA ;
     font-weight:400;
     font-size:16px;
     text-align:center;
}
.desktop-top-header .cart-column .fa.fa-shopping-cart, .mobile-header .fa.fa-shopping-cart {
     display:none;
}
.desktop-top-header .feature-icon-hover {
     padding-right:0 !important;
}
.desktop-top-header .feature-icon-hover .caret {
     border-top-color:#363636;
}
.desktop-top-header .cart-column img {
     max-width:28px;
}
.desktop-category-column ul#nav {
     background-color:#0063A4;
}
.desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-category-column .nav-regular li.level0>a {
     text-transform:inherit;
}
.desktop-category-column .nav-regular li.level0>a>span {
     font-weight:500;
     font-size:16px;
     color:#fff;
     text-transform:inherit;
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.desktop-category-column ul#nav {
     display:flex;
     justify-content:center;
}
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
#inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:100%;
         right:0px;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:767px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
@media only screen and (max-width:959px) {
     #header {
         border-bottom:1px solid #0063a4;
    }
     .desktop-top-header, .desktop-top-header .cart-column, .desktop-top-header .cart-column .module-header-multi {
         overflow:unset;
    }
}
/*Mobile*/
@media only screen and (max-width:767px) {
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         overflow:hidden !important;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard, .mobile-header .item-search:not(.fly-out) .fa.fa-search {
         display:none !important;
    }
     .mobile-header .header .logo-wrapper {
         text-align:left !important;
         margin:0 !important;
    }
     .mobile-header #mini-cart .empty {
         display:none;
    }
     .mobile-header #mini-cart .hide-below-960 img {
         margin-top:10px;
    }
     .mobile-header .logo img {
         max-height:60px;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa, .mobile-header .item-search.fly-out .fa.fa-search {
         font-size:16px;
         color:#777;
         padding:10px;
         font-style:normal;
    }
     .mobile-header .item-search.fly-out .button-search img {
         display:none;
    }
     .mobile-header .item-search.fly-out .ajax-search-flyout {
         border-radius:5px;
         background-color:#EFEAEA;
         color:#363636;
    }
}
/*Misc*/
.page-content ul, #product-tabs .std ul, .product-shop.module-product-details ul:not(.add-to-links) {
     list-style:disc;
     margin-left:25px;
}
#header {
     position:sticky;
     top:0;
     z-index:50;
}
@media print {
     .ord-details .grid-column-wrapper {
         width:98%;
    }
     .module-trans-details-listing {
         max-width:95%;
    }
     .module-order-details-listing {
         max-width:95%;
    }
     @page {
         size:landscape 
    }
}
.isPublic .hide-for-B2C {
     display:none;
}
body:not(.isPublic) .hide-for-B2B {
     display:none;
}
input.input-text, select, textarea {
     font-family:'Roboto', sans-serif;
     font-size:16px;
     color:rgba(51,52,62,.85) !important;
     border-radius:0px;
}
input.input-text[disabled=disabled], input[disabled=disabled], input.input-text[disabled=disabled]::placeholder, input[disabled=disabled]::placeholder {
     color:#363636 !important;
     -webkit-text-fill-color:rgba(0, 0, 0, 0.8);
     -webkit-opacity:0.8;
}
/**/
/*.footer-top.footer.container{
     width:96%;
     max-width:1060px;
}*/
@media only screen and (max-width:479px) {
     .footer-top.footer.container {
         max-width:none !important;
         width:94%;
         padding-left:3%;
         padding-right:3%;
    }
}
#footer a, #footer p, #footer a:hover, .footer-addr div, #footer span {
     color:#fff;
}
.footer-top.footer .moto-column {
     width:29%;
     padding-right:2%;
}
.footer-addr-col {
     padding-right:2%;
}
.moto-logo img {
     max-width:170px;
}
.moto-txt {
     line-height:12px;
     font-size:9px;
     margin-top:20px;
}
.moto-txt span, .copyright-txt p{
     line-height:1px;
     font-size:9px;
     font-weight:500;
}
.copyright-txt p {
     line-height:10px;
}
.footer-links-div {
     border-left:1px solid #fff;
     padding-left:7px;
     display:flex;
     flex-direction:column;
}
.addr-hd {
     font-size:16px;
     font-weight:900;
     margin-bottom:10px;
}
.footer-addr {
     display:flex;
     justify-content:space-between;
}
.footer-addr div, .footer-links-div a {
     font-size:12px;
     font-weight:400;
     line-height:1.7;
}
.footer-addr span {
     font-size:12px;
     font-weight:900;
     line-height:1.9;
}
.copyright-col {
     margin:20px 0 30px;
     text-align:center;
}
.footer-logo-column {
     margin-top:30px;
}
#scroll-to-top {
     display:none !important;
}
@media only screen and (max-width:959px) {
     .footer-top.footer .moto-column, .footer-addr-col {
         width:37% !important;
    }
     .tnc-column {
         width:18% !important;
    }
     .footer-logo-column {
         width:98%;
         text-align:center;
         margin-top:15px;
    }
     .footer-logo-column img {
         max-height:70px;
    }
     .footer-addr{
         justify-content:space-between;
    }
     .copyright-col {
         margin:10px 0;
    }
     .moto-logo img {
         max-width:140px;
    }
}
 @media only screen and (max-width:767px) {
     .footer-top.footer.container {
         width:99%;
         max-width:100%;
         padding-left:1%;
    }
     .footer-addr div, .footer-links-div a, .footer-addr span {
         font-size:9px;
    }
     .addr-hd {
         font-size:10px;
         line-height:15px;
    }
     .moto-txt span, .copyright-txt p {
         font-size:7px;
    }
     .moto-txt {
         margin-top:10px;
    }
     .logo-img-div img {
         max-height:50px;
    }
     .moto-logo img {
         max-width:120px;
    }
     .text-top {
         margin-top:0 !important;
    }
}

/*Category pages*/
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.product-listing .add-cart-button span span{
     padding:0px !important; 
     font-weight: 500;
}
.product-listing .page-title h1 {
     padding-top:0;
     padding-bottom:0px;
}
.product-search-container, .breadcrumbs-col .module-category-misc > div, #breadcrumbs li.home span, .breadcrumbs-col .nested-container {
     margin-left:0 !important;
}
#breadcrumbs li.last-child > span {
     vertical-align:super;
}
.breadcrumb-separator {
     background:none !important;
     text-indent:0 !important;
     overflow:unset !important;
     margin:0 !important;
     font-size:14px !important;
     width:auto !important;
     margin-top:0px !important;
}
#breadcrumbs > li:last-child span {
     font-weight:700;
}
#breadcrumbs li a {
     color:#000;
}
.product-listing .responsive-column img {
     width:inherit !important;
     height:inherit !important;
}
.pager {
     border:none;
     margin:0;
     padding:0;
}
.pager li a, .pager strong{
     font-size:14px;
}
.pager .pages li.current {
     background-color:#fff;
}
.pager .pages li a:hover {
     background-color:#fff;
     color:#000 !important;
}
.toolbar-section .module-category-misc {
     display:flex;
     justify-content:flex-end;
     flex-wrap:wrap;
}
.category-products-grid .item {
     border:none;
     margin-bottom:50px;
     padding-bottom:85px !important;
}
.category-products-grid .product-image img {
     max-width:180px;
}
.item .module-category-product-listing h2.product-name {
     margin-bottom:15px;
     min-height: 80px;
}
.item .module-category-product-listing h2.product-name a {
     color:#000;
}

.item .module-category-product-listing .product-image-wrapper.product-description-wrapper {
     margin-bottom:30px;
}
.item .module-category-product-listing .product-description-wrapper .product-shop-inner {
     min-height:60px;
}
.item .module-category-product-listing .price-box {
     margin-top:15px;
}
.item .module-category-product-listing .price-box span, .item .module-category-product-listing .item-code{
     font-weight:600;
}
@media only screen and (min-width:768px) {
     .item .module-category-product-listing .add-cart-wrapper {
         display:flex;
         justify-content:center;
    }
     .item .module-category-product-listing .add-cart-wrapper > .qty-wrapper{
         margin:auto 10px;
    }
     .item .module-category-product-listing .add-cart-wrapper > button{
         margin:auto;
         margin-left:10px;
    }
}
.module-category-product-listing .add-cart-wrapper input.qty {
     height:40px;
     border:1px solid #000;
}
.products-line .module-category-product-listing .module-category-product-listing__actionButtons .qty-wrapper input.qty {
     width:3em !important;
}
@media only screen and (max-width:959px) {
     .item .module-category-product-listing .grid12-12, .breadcrumbs-col, .breadcrumbs-col .grid-column-wrapper {
         overflow:hidden;
    }
     .item .module-category-product-listing .item-code-label {
         display:none;
    }
     .mobile-filters .fa-sliders:before {
         font-size:22px;
         font-weight:400;
    }
}
/*Hover effect*/
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
/*Expand the width for small width devices*/
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
}
@media only screen and (min-width:1400px) {
     .item .module-category-product-listing a, .item .module-category-product-listing span, .item .module-category-product-listing .price-box .price, .item .module-category-product-listing div, .item .module-category-product-listing .price-box .price span {
         font-size:18px;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
     .products-grid .item button.button span span {
         padding:0px !important;
         height:40px !important;
         line-height:40px;
    }
     .pager .pages li {
         display:inherit;
    }
     .button.quickviewbutton {
         display:none;
    }
     .product-listing .category-products-grid .item {
         padding-bottom:120px !important;
    }
     .category-products-grid .product-image img {
         max-width:150px;
    }
}
.category-products-listing{
     margin-top:40px;
}
/* Quick View Dialog*/
#quick-view-modal .modal-footer {
     border-top:none;
}
#quick-view-modal .add-cart-button{
     margin-top:15px;
}
#quick-view-modal button.button span span {
     max-width:100px;
     padding:0 40px;
}
#quick-view-modal button.button span {
     border-radius:0px;
}
.quick-view-modal-dialog .product-view .btn-cart span span {
     padding:0 20px !important;
}
.quick-view-modal-dialog .button.pull-right {
     color:#000;
}
/**/
.cart .page-title h1 {
     font-size:30px;
}
.cart span.item-secondary-heading {
     display:none!important;
}
.cart-action-buttons button {
     margin:20px 10px;
}
.cart .totals {
     border-radius:12px;
     background:var(--Light-Grey, rgba(212, 212, 212, 0.47));
}
 .cart-summary-col {
     float:right;
}
@media only screen and (min-width:960px){
     .cart-action-buttons .button.btn-update.btn-save-change {
         float:right;
    }
}
 .cart-summary-col{
     margin-top:30px;
}
@media only screen and (max-width:767px) {
     #shopping-cart-table {
         overflow:hidden;
    }
     .cart-action-buttons button{
         margin:10px;
    }
}
@media only screen and (min-width:1024px){
     .cart-summary-col{
         width:40%;
    }
}
@media only screen and (min-width:1600px){
     .cart-summary-col{
         width:30%;
    }
}
@media only screen and (max-width:767px) {
     .checkout-multishipping-shipping .rTableCell {
         width:95%;
    }
     .checkout-multishipping-shipping .search-box .search-btn-quickorder {
         top:30px;
    }
}
body.cart .data-table:not(.cart-table) .col-img img {
     width:60%;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .opc-main-container.onepage-checkout-main-container, .in-sidebar.opc-progress-container {
         width:98%;
    }
}
@media only screen and (max-width:767px) {
     body.cart .data-table tfoot td {
         display:inline-block !important;
         width:30% !important;
         min-width:100px;
         max-width:140px;
         margin-right:10px;
         font-weight:700;
    }
}
#shopping-cart-table h2.product-name .item-code {
     display:none;
}
/**/
.progress, div[class^=strength_meter_passwd] {
     top:25px !important;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide {
     margin-bottom:40px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu {
     display:flex;
     float:left;
     width:100%;
     background-color:#fff;
     padding-left:0 !important;
     list-style:none;
     justify-content:center;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li {
     padding:0px 20px;
     line-height:36px;
     height:36px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li.selected-navitem a, .account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li:hover a{
     border-bottom:1px solid #0063A4 ;
     border-radius:0px;
}
.account-view .sidebar.my-account-sidebar.mmenu-hide ul.xm-mega-menu > li > a{
     padding:0;
     font-size:16px;
     color:#000;
     text-transform:uppercase;
     line-height:36px;
     height:36px;
     transition:color 0.0s ease;
}
.account-view .block-content.mm-menu ul.xm-mega-menu > li {
     width:100%;
}
#order-table, #transhistory-table, #address-book-table{
     margin-top:20px;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child a{
     display:block;
     width:90px;
     text-align:center;
}
 #order-table .ReactTable .rt-tbody .rt-tr .rt-td:last-child span{
     display:none;
}
.buttons-set.module-order-details-actions {
     text-align:end;
}
.buttons-set.module-order-details-actions .back-link {
     margin-bottom:1px;
}
.buttons-set.module-order-details-actions .fa-angle-double-left:before {
     content:" " !important;
}
.order-info-box h2 {
     font-size:28px !important;
     line-height:30px;
}
.order-info-box .box-content {
     margin-top:25px;
}
.addresses-list h3 {
     font-weight:700;
}
@media only screen and (min-width:960px) {
     .order-info-box.module-order-details-billing, .order-info-box.module-order-details-shipping, .order-info-box.module-shipment-details-billing, .order-info-box.module-trans-details-shipping, .order-info-box.module-quote-details-billing, .order-info-box.module-order-quote-shipping{
         min-height:300px;
    }
     .col-1.addresses-primary .item, .col-2.addresses-primary .item{
         min-height:300px;
    }
     .module-order-details-misc > div {
         margin-left:5px;
    }
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .billing-module, .shipping-module, .col-1.addresses-primary, .col-2.addresses-primary {
         width:99%;
    }
     .col-2.addresses-primary {
         float:left;
    }
}
a.button span span, .account-view button.button.btn-inline span span {
     padding:0 12px;
     font-size:16px;
     font-weight:500;
     height:40px!important;
     line-height:40px!important;
     display:inline-block;
     background-color:#0063A4 ;
}
a.button span, .account-view button.button.btn-inline span {
     color:#fff;
     border-radius:0;
}
.account-view a.button:hover span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, .account-view button.button.btn-inline:hover span {
     background-color:transparent !important;
}
.account-view a.button:hover span span, .module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie:hover, .account-view button.button.btn-inline:hover span span{
     background-color:#0063A4 !important;
}
.po-invc .pager .amount {
     padding-top:0;
}
.po-invc #form_filter_user_data input, .po-invc #form_filter_user_data button {
     margin-left:10px;
}
.po-invc .buttons-set p.back-link {
     display:none;
}
@media only screen and (max-width:767px) {
     .po-invc .my-account .pager p.amount, .po-invc .filter_order_search_label, .po-invc .my-account > p {
         font-size:12px !important;
    }
     .po-invc #form_filter_user_data input {
         width:100px;
    }
     .po-invc .my-account .pager p.amount {
         padding-left:5px;
         float:left;
    }
     .po-invc button.button span {
         font-size:16px;
    }
     #my-invoices-table, #my-orders-table {
         overflow:hidden;
    }
}
.module-trans-details-misc .item-options dd span {
     line-height:2px;
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
}
.login-page-title h1 {
     text-align:center;
}
.login-section .grid-container {
     display:flex;
     justify-content:space-around;
}
.login-section .grid-column-wrapper {
     width:auto;
     margin-left:100px;
}
@media only screen and (max-width:767px) {
     .login-section .grid-column-wrapper {
         margin-top:5%;
         margin-left:50px;
    }
}
@media only screen and (max-width:767px) {
     button[title='Change Email'] {
         margin-bottom:50px !important;
    }
     .login-section h2 {
         font-size:20px;
    }
}
.login-section .buttons-set{
     margin-left:0;
}
.login-section .buttons-set button{
     float:left;
}
.module-login-form.login-form-wrapper ul.form-list li:nth-child(1){
     margin-bottom:10px;
}
.module-login-form.login-form-wrapper ul.form-list li:nth-child(2){
     margin-bottom:20px;
}
.module-login-form.login-form-wrapper div.buttons-set{
     display:flex;
     flex-direction:column-reverse;
     justify-content:start;
     margin-left:0;
}
.module-login-form.login-form-wrapper p.required {
     display:none;
}
.module-login-form.login-form-wrapper div.buttons-set > a{
     line-height:50px;
}
/*Transaction details*/
.page-title.module-trans-details-status h1 span, .page-title.module-order-details-status h1 {
     font-size:32px;
}
.module-trans-details-actions .btn.btn-outline-secondary.btn-pay-invocie {
     padding:10px;
     background-color:#0063A4 ;
     cursor:pointer;
     color:#fff;
     border-radius:0;
     border:none;
}
.module-trans-details-actions input.form-control {
     height:30px;
     width:80px;
}
@media only screen and (min-width:768px) and (max-width:959px) {
     .xm-grid-account .grid-container-wrapper {
         overflow:unset;
    }
     .fa-bars:after {
         color:#fff !important;
    }
}
@media only screen and (max-width:959px) {
     .page-title.module-trans-details-status h1 span {
         font-size:32px !important;
    }
     .ReactTable .rt-thead.-filters input{
         max-width:80px;
    }
     .ReactTable .rt-thead.-filters select{
         width:80%;
         height:26px;
    }
     .ReactTable a, .ReactTable div, .ReactTable p, .ReactTable span{
         font-size:11px !important;
    }
}
.due-input, .module-trans-details-actions .form-control {
     font-size:16px;
}
/*Redo of the login screen*/
.main.container.login-screen {
     width:100%;
     max-width:100%;
     margin:0;
}
.main.container.login-screen .user-grid-content, .main.container.login-screen .user-grid-content .col-main {
     margin:0 !important;
     width:100%;
}
.login-frm {
     position:absolute;
     top:30%;
     left:50%;
     z-index:10;
     transform:translate(-50%, -30%);
     box-shadow:0px 11px 7px 0px rgba(0, 0, 0, 0.25);
     margin:auto;
     float:none;
     display:flex;
     justify-content:center;
     align-items:center;
     width:350px !important;
     height:400px;
     background:#353A41;
     opacity:0.84;
}
.login-frm .module-login-form.login-form-wrapper > .content > p {
     display:none;
}
.login-frm .module-login-form.login-form-wrapper h2, .login-frm .module-login-form.login-form-wrapper a, .login-frm .module-login-form.login-form-wrapper label {
     color:#fff;
     font-weight: 400;
}
.login-frm .module-login-form.login-form-wrapper .input-text {
     background-color:transparent;
     border:none;
     border-bottom:1px solid #fff;
     color:#fff !important;
}
.login-frm .module-login-form.login-form-wrapper h2 {
     text-align:center;
     margin-bottom:40px;
}
.module-login-form.login-form-wrapper ul.form-list li:nth-child(1) {
     margin-bottom:30px;
}
.module-login-form.login-form-wrapper div.buttons-set {
     justify-content:center;
     margin:auto;
     margin-top:30px;
     align-items:center;
}
.module-login-form.login-form-wrapper div.buttons-set > a {
    text-align:center;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 5px;
}
@media only screen and (max-width:1299px) {
     .login-frm {
         width:300px !important;
         height:330px;
    }
     .login-frm .module-login-form.login-form-wrapper h2 {
         margin-bottom:20px;
         font-size:24px;
    }
     .module-login-form.login-form-wrapper ul.form-list li:nth-child(1) {
         margin-bottom:20px;
    }
     .login-frm .module-login-form.login-form-wrapper .input-text {
         height:26px;
         line-height:26px;
         width:220px;
    }
}
@media only screen and (max-width:767px) {
     .main.container.login-screen {
         padding:0;
    }
     .mobile-banner .owl-carousel .owl-item img{
         min-height:350px 
    }
     .login-frm {
         width:70% !important;
         max-width: 320px;
    }
}

/*Add sorting arrows on React Tables*/
.ReactTable .rt-th.-cursor-pointer .rt-resizer {
     background-size:18px 18px;
     background-image:
       url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg=='), 
      url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=');
     background-repeat:no-repeat;
     background-position:6px 5px, 20px 6px;
     right:0;
}
.ReactTable .rt-th.-sort-asc .rt-resizer {
     background-image:
       url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMTVWNmw0IDQgMS0xLTYtNi02IDYgMSAxIDQtNHY5eiIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
}
.ReactTable .rt-th.-sort-desc .rt-resizer {
     background-image:
       url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOCAzdjkuMTNMNCA4IDMgOWw2IDYgNi02LTEtMS00IDQuMTNWM3oiIGZpbGw9IiMzMzMiLz48L3N2Zz4=);
}

 /*R7 & XPR Order form*/
 .r7-form div.fieldset, .xpr-form div.fieldset {
     padding:0;
}
 .r7-form ul.form-list > div.fieldset > li.fields, .xpr-form ul.form-list > div.fieldset > li.fields {
     display:flex;
     align-items:center;
     margin-bottom:30px;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(n+15) {
     margin-bottom:50px;
}
 .r7-form ul.form-list > div.fieldset > li.fields .input-box, .xpr-form ul.form-list > div.fieldset > li.fields .input-box {
     margin-left:20px;
     flex:1;
}
 .r7-form ul.form-list > div.fieldset > li.fields > label, .xpr-form ul.form-list > div.fieldset > li.fields > label {
     min-width:300px;
     text-align:right;
     padding-right:5px;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(1), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(2), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(5), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(6), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(11), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(12), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(13), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(14), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(1), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(2), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(5), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(6), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(8), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(10), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(11), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(12), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(13), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(14) {
     width:100%;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(3), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(4), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(7), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(8), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(9), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(10), .r7-form ul.form-list > div.fieldset > li.fields:nth-child(n+15), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(3), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(4), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(7), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(8), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(9), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(10), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(15), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(16), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(17), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(18){
     width:50%;
     float:left;
}
 .r7-form ul.form-list > div.fieldset > li.fields input.input-text, .r7-form ul.form-list > div.fieldset > li.fields textarea, .xpr-form ul.form-list > div.fieldset > li.fields input.input-text, .xpr-form ul.form-list > div.fieldset > li.fields textarea {
     width:100%;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(1), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(1) {
     border-bottom:2px solid #0063a4;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(1) h4, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(1) h4 {
     color:#0063a4;
     font-size:30px;
     font-weight:700;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(15) div.input-box, .r7-form ul.form-list > div.fieldset > li.fields:nth-child(16) div.input-box, .r7-form ul.form-list > div.fieldset > li.fields:nth-child(18) div.input-box, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(17) div.input-box {
     display:flex;
}
 .r7-form ul.form-list > div.fieldset > li.fields:nth-child(15) div.input-box > div{
     display:flex;
     flex-direction:column-reverse;
     justify-content:center;
     align-items:center;
     margin-left:30px;
     row-gap:10px;
     font-weight:700;
}
 @media only screen and (min-width:1600px) {
     .r7-form ul.form-list > div.fieldset > li.fields label, .xpr-form ul.form-list > div.fieldset > li.fields label {
         font-size:18px;
    }
}
 .r7-form .grid-column-wrapper > div.fieldset, .xpr-form .grid-column-wrapper > div.fieldset {
     text-align:center;
}
.xpr-form ._buttons-set .button {
     float:none !important;
}
 .xpr-form ._buttons-set {
     width:100%;
     float:left;
}
.r7-form ._buttons-set .button{
     position:relative;
     left:50%;
     transform:translateX(-50%);
}
 .r7-form input[type="radio"], .xpr-form input[type="radio"] {
     transform:scale(1.5);
}
.r7-form ul.form-list > div.fieldset > li.fields:nth-child(16) div.input-box > div, .r7-form ul.form-list > div.fieldset > li.fields:nth-child(18) div.input-box > div, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(17) div.input-box > div {
     display:flex;
     flex-direction:column-reverse;
     justify-content:center;
     align-items:center;
     gap:10px;
     flex:1;
}
.r7-form ul.form-list > div.fieldset > li.fields:nth-child(16) div.input-box > div label, .r7-form ul.form-list > div.fieldset > li.fields:nth-child(18) div.input-box > div label, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(17) div.input-box > div label {
     font-weight:700;
}
.r7-form ul.form-list > div.fieldset > li.fields:nth-child(17) div.input-box > div label, .r7-form ul.form-list > div.fieldset > li.fields:nth-child(20) div.input-box > div label, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(15) div.input-box > div label, .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(16) div.input-box > div label {
     display:none;
}
.r7-form ul.form-list > div.fieldset > li.fields:nth-child(17) .input-box input[type=checkbox], .r7-form ul.form-list > div.fieldset > li.fields:nth-child(20) .input-box input[type=checkbox], .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(15) .input-box input[type=checkbox], .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(16) .input-box input[type=checkbox] {
     width:25px;
     height:25px;
     float:left;
}
.r7-form ul.form-list > div.fieldset > li.fields:nth-child(19) {
     float:inline-end;
}
 @media only screen and (max-width:1279px) {
     .r7-form ul.form-list > div.fieldset > li.fields > label {
         min-width:230px !important;
    }
}
 @media only screen and (max-width:959px) {
     .r7-form ul.form-list > div.fieldset > li.fields:nth-child(n+1), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(n+1) {
         width:100%;
         display:block;
    }
     .r7-form ul.form-list > div.fieldset > li.fields .input-box, .xpr-form ul.form-list > div.fieldset > li.fields .input-box {
         margin-left:0;
    }
     .r7-form ul.form-list > div.fieldset > li.fields > label, .xpr-form ul.form-list > div.fieldset > li.fields > label {
         min-width:unset !important;
    }
     .r7-form ul.form-list > div.fieldset > li.fields label, .xpr-form ul.form-list > div.fieldset > li.fields label {
         font-size:14px;
    }
     .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(14) div.input-box > div {
         justify-content:start;
    }
}
 @media only screen and (min-width:767px) and (max-width:959px) {
     .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(15), .xpr-form ul.form-list > div.fieldset > li.fields:nth-child(16) {
         width:50%;
         display:inline-block;
    }
}
